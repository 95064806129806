<script setup>
 // Composables
 import { getEnv } from '@/composables/helpers/getEnv';
 const { links, attributes } = defineProps({
  links: {
   type: Object,
   required: true,
  },
  absoluteLinks: {
   type: Boolean,
   default: false,
  },
 });

 //  Hack for TNEW
 const clientFrontEnd = getEnv('clientFrontEnd');

 const ordered = ['instagram', 'linkedin', 'facebook', 'pinterest', 'x'];
 const orderedSocials = {};
 ordered.forEach((item) => {
  orderedSocials[item] = links[item];
 });

 function save() {
  console.log('saved!');
 }
</script>
<template>
 <section class="red-banner">
  <div class="container">
   <div class="newsletter-wrapper">
    <p class="message">Stay updated with CAC’s newsletter</p>
    <ProjectButton
     :link="absoluteLinks ? clientFrontEnd + '/subscribe' : '/subscribe'"
     :forceExternalLink="absoluteLinks"
     label="Subscribe Now"
    />
   </div>
   <div class="social-wrapper">
    <div class="inner-wrapper">
     <p class="message">Follow us on social media</p>
     <ul class="socials">
      <li v-for="(link, index) in orderedSocials" :key="index" class="social">
       <MessLink :href="link">
        <span class="sr-only">Links to social media site {{ index }}</span>
        <ProjectSvg :type="`socials-${index}`" />
       </MessLink>
      </li>
     </ul>
    </div>
   </div>
  </div>
 </section>
</template>

<style lang="scss">
 .global-footer {
  .red-banner {
   background: var(--red);
   min-height: 100px;
   display: flex;
   .container {
    display: flex;
    gap: 4rem;
    margin-top: 0;
    margin-bottom: 0;

    > * {
     flex: 1;
    }

    .message {
     color: var(--white, #fff);
     font-size: 20px;
     font-style: normal;
     font-weight: 900;
     line-height: 135.6%; /* 27.12px */
     letter-spacing: 0.3px;
    }

    .newsletter-wrapper {
     position: relative;
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     justify-content: flex-start;
     gap: 1.25rem;
     padding: 1.5rem 0;

     .mess-button {
      color: var(--white);
      padding: 1.125rem 1.5rem;

      span {
       color: var(--White, #fff);
       text-align: center;
       font-size: 12px;
       font-style: normal;
       font-weight: 900;
       line-height: 123.1%; /* 14.772px */
       letter-spacing: 1.38px;
       text-transform: uppercase;
      }

      &:hover {
       background: var(--black);
      }

      &::after {
       content: '';
       position: absolute;
       width: 2px;
       background: var(--white);
       height: 100%;
       right: calc(-2rem - 1px);
       top: 0;
      }
     }
    }
   }

   .social-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 1.5rem 0;

    .inner-wrapper {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     flex-wrap: wrap;
     gap: 1.5rem;
     .socials {
      display: flex;
      gap: 1rem;

      .social {
       position: relative;
       aspect-ratio: 1 / 1;
       width: 2.25rem;

       a {
        @include absolute-center;
        aspect-ratio: 1 / 1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: white;
        transition: 250ms ease;

        .mess-svg {
         @include absolute-center;
         width: 50%;
         height: 50%;
         transition: 250ms ease;
        }

        &:hover {

         .mess-svg {
          transform: scale(0.8);
          opacity: 0.8;
         }
        }
       }
      }
     }
    }
   }
  }

  @media (max-width: $tablet) {
   .red-banner {
    .container {
     flex-direction: column;
     gap: 0;

     .newsletter-wrapper {
      padding-top: 3rem;
     }

     .social-wrapper {
      justify-content: flex-start;
      padding-bottom: 3rem;
      .inner-wrapper {
       justify-content: flex-start;
      }
     }
    }
   }
  }
 }
</style>
